<template>
  <div class="d-flex flex-column align-items-center col-12">
    <div class="navbar-container">
      <div v-if="windowWidth >= 500">
        <DesktopNavBar/>
      </div>
      <div v-if="windowWidth < 500">
        <MobileNavBar/>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center dashboard-container col-12 mt-4">
      <button @click="logoutClicked()" v-if="windowWidth >= 500" class="logout-button">Logout</button>
      <div class="header-text">Your Profile!</div>
      <img :src="profilePic" class="profile-pic-img mt-3"/>
      <div class="d-flex flex-row mt-3 user-name-container">
        <div> {{ userData.Name + ' ' + userData.Surname }}</div>
        <!--        <img class="edit-icon" src="../assets/edit-icon.png">-->
      </div>
      <div class="col-xl-3 col-10 mt-3">
        <div class="d-flex flex-row align-items-center info-box mb-2">
          <div class="d-flex flex-row justify-content-center col-2">
            <img src="../../assets/mail-icon.png" style="width: 20px">
          </div>
          <div class="col-10 info-text">{{ userData.Email }}</div>
        </div>
        <div class="d-flex flex-row align-items-center info-box mb-2">
          <div class="d-flex flex-row justify-content-center col-2">
            <img src="../../assets/phone-icon.png" style="width: 20px">
          </div>
          <div class="col-10 info-text">{{ this.splitStringEveryThreeCharacters(0 + userData.Msisdn.substring(2)) }}</div>
        </div>
        <div @click="changePasswordClicked()" class="d-flex flex-row align-items-center info-box mb-2">
          <div class="d-flex flex-row justify-content-center col-2">
            <img src="../../assets/lock-icon.png" style="width: 20px">
          </div>
          <div class="col-10 info-text">Change Password</div>
        </div>
        <div class="d-flex flex-row align-items-center info-box mb-2">
          <div class="d-flex flex-row justify-content-center col-2">
            <img src="../../assets/football-icon.png" style="width: 20px">
          </div>
          <div class="col-10 info-text">{{ userData.Team }}</div>
        </div>
        <div class="d-flex flex-row align-items-center info-box mb-2">
          <div class="d-flex flex-row justify-content-center col-2">
            <img src="../../assets/calendar-icon.png" style="width: 20px">
          </div>
          <div class="col-10 info-text">{{ this.formatBirthDate(userData.DateOfBirth) }}</div>
        </div>
        <div class="d-flex flex-row align-items-center info-box mb-2">
          <div class="d-flex flex-row justify-content-center col-2">
            <img src="../../assets/kin-icon.png" style="width: 20px">
          </div>
          <div class="col-10 info-text">{{ userData.NextOfKinName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import MobileNavBar from "@/views/components/mobile/MobileNavBar";
import DesktopNavBar from "@/views/components/desktop/DesktopNavBar";
import {ref} from "vue";
import moment from "moment";

const windowWidth = ref(window.innerWidth)
const handleResize = () => {
  windowWidth.value = window.innerWidth
}

export default {
  name: "UserProfile",
  computed: {
    ...mapState(['userData']),
  },
  components: {MobileNavBar, DesktopNavBar},
  data() {
    return {
      windowWidth: windowWidth,
      profilePic: undefined,
    }
  },
  methods: {
    ...mapMutations(['setUserData', 'setIsLoading']),
    setUserProfilePic() {
      const azureAccount = process.env.VUE_APP_AZURE_ACCOUNT;
      const containerName = process.env.VUE_APP_CONTAINER_NAME;
      const {Name, Surname} = this.userData;

      this.profilePic = `https://${azureAccount}.blob.core.windows.net/${containerName}/${Name}_${Surname}`;
    },
    splitStringEveryThreeCharacters(inputString) {
      let regex = /(\S{1,3})(\S{1,3})/;

      let resultString = inputString.replace(regex, function (match, group1, group2, group3) {
        return (group1 ? group1 + ' ' : '') + (group2 ? group2 + ' ' : '') + (group3 ? group3 + ' ' : '');
      });

      return resultString.trim();
    },
    formatBirthDate(birthDate) {
      return moment(birthDate.substring(0, 10)).format("Do MMM YYYY")
    },
    routeToPage(pageRoute) {
      this.$router.push(`/${pageRoute}`);
    },
    logoutClicked() {
      this.setUserData(undefined);
      this.routeToPage('login');
    },
    changePasswordClicked() {
      this.routeToPage('forgot-password')
    },
  },
  beforeMount() {
    this.setIsLoading(true);
    this.setUserProfilePic();
    this.setIsLoading(false);
  },
  mounted() {
    window.addEventListener('resize', handleResize)
  },
  unmounted() {
    window.removeEventListener('resize', handleResize)
  },
}
</script>

<style scoped>
.dashboard-container {
  position: relative;
  width: 100vw;
}

.header-text {
  font-size: 60px;
  color: #EE5C36;
  line-height: 1.1;
}

.logout-button {
  position: absolute;
  background-color: #EE5C36;
  color: #FFFFFF;
  font-size: 13px;
  right: 10px;
  top: -10px;
  border: 0;
  height: 36px;
  width: 100px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.profile-pic-img {
  width: 175px;
  height: 175px;
  border-radius: 50%;
  background-position: center center;
  background-size: cover;
  border: 1px solid #EE5C36;
}

.user-name-container {
  position: relative;
  font-size: 22px;
}

.info-box {
  height: 38px;
  border-radius: 15px;
  background-color: #EE5C36;
  color: #FFFFFF;
}

.info-text {
  font-size: 13px;
  font-style: italic;
}

.edit-icon {
  position: absolute;
  width: 25px;
  right: -30px;
  top: 2px;
}

@media screen and (max-width: 500px ) {
  .header-text {
    font-size: 40px;
  }
}
</style>
